export const LOAD_ALL_POSTS = 'posts/LOAD_ALL_POSTS';
export const LOAD_ONE_POST = 'posts/LOAD_ONE_POST';
export const FETCH_MORE_POSTS_SUCCESS = 'FETCH_MORE_POSTS_SUCCESS';
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR';
export const RESET_POSTS = 'RESET_POSTS';

export const loadAllPosts = (posts) => {
  return {
    type: LOAD_ALL_POSTS,
    posts
  }
};

export const loadOnePost = (post) => {
  return {
    type: LOAD_ONE_POST,
    post
  }
};

export const resetPosts = () => ({
  type: RESET_POSTS
});

export const fetchAllPosts = () => async(dispatch) => {
  const response = await fetch('/api/posts');

  if(response.ok) {
    const data = await response.json();
    dispatch(loadAllPosts(data));
    return data
  } else {
    const errors = await response.json();
    return errors
  }
};

export const fetchMorePosts = (page, limit = 16) => async(dispatch) => {
  try {
    const response = await fetch(`/api/posts/test?page=${page}&limit=${limit}`);
    const data = await response.json();

    if (response.ok) {
      dispatch({ type: 'FETCH_MORE_POSTS_SUCCESS', payload: data });
    } else {
      throw new Error('Failed to fetch posts');
    }
  } catch (error) {
    console.error('Error fetching more posts:', error);
    dispatch({ type: 'FETCH_POSTS_ERROR', error: error.toString() });
  }
}

export const fetchOnePost = postId => async(dispatch) => {
  const response = await fetch(`/api/posts/${postId}`);
  if(response.ok) {
    const data = await response.json();
    dispatch(loadOnePost(data));
    return data
  } else {
    const errors = await response.json();
    return errors
  }
};

export const checkPreviousPostExists = postId => async(dispatch) => {
  const response = await fetch(`/api/posts/check/${postId}/previous`);
  const data = await response.json();

  if(response.ok) {
    return data.previousPostId
  } else {
    throw data
  }
};

export const checkNextPostExists = postId => async(dispatch) => {
  const response = await fetch(`/api/posts/check/${postId}/next`);
  const data = await response.json();

  if(response.ok) {
    return data.nextPostId
  } else {
    throw data
  }
}

export const fetchCreatePost = FormData => async(dispatch) => {
  const response = await fetch('/api/posts/new', {
    method : ["POST"],
    body: FormData
  });

  if(response.ok) {
    const data = await response.json();
    return data;
  } else {
    const errors = await response.json();
    return errors;
  }
};

export const fetchUpdatePost = (FormData, postId) => async(dispatch) => {
  const response = await fetch(`/api/posts/${postId}`, {
    method: 'PUT',
    body: FormData
  });

  if(response.ok) {
    const data = await response.json();
    return data;
  } else {
    const errors = await response.json();
    return errors;
  }
};


const initialState = { allPosts: {}, onePost: {}, error: null, totalCount: 0, currentPage: 1, totalPages: 1};
const postReducer = (state = initialState, action) => {
  let newState;
  switch(action.type) {
    case LOAD_ALL_POSTS:
      newState = { ...state, allPosts : {...action.posts }};
      return newState
    case LOAD_ONE_POST:
      newState = { ...state, onePost : { ...action.post }}
      return newState
    case FETCH_MORE_POSTS_SUCCESS:
      newState = {
        ...state,
        allPosts: { ...state.allPosts, ...action.payload.posts},
        totalCount: action.payload.totalCount,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages
      };
      return newState;
    case FETCH_POSTS_ERROR:
      newState = { ...state, error: action.error };
      return newState;
    case RESET_POSTS:
      return initialState;
    default:
      return state
  }
};

export default postReducer
