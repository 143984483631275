import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMorePosts } from "../../store/post";
import { fetchUserPlaylists } from "../../store/user";
import Post from "../Post";
import "./Home.css";
import PlaylistModal from "../Playlist/PlaylistModal";
import { fetchCreateUserPlaylist } from "../../store/user";
import Modal from "@mui/material/Modal";
import { Box, Fade } from "@mui/material";
import PlaylistForm from "../Playlist/PlaylistForm";
import Loading from "../Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import TabPanel from "../TabPanel";
import { resetPosts } from "../../store/post";
import { Puff } from "react-loader-spinner";

function Home() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.allPosts);
  const currUser = useSelector((state) => state.session.user);
  const playlists = useSelector((state) => state.user.UserPlaylists);
  const user = useSelector((state) => state.user.User);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [createPlaylistModalOpen, setCreatePlaylistModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [page, setPage] = useState(1);
  const totalPosts = useSelector((state) => state.posts.totalCount || 0);
  const hasMore = Object.keys(posts).length < totalPosts;
  const [value, setValue] = useState(0);

  // for initial load
  useEffect(() => {
    const loadInitialPosts = async () => {
      setLoading(true);
      try {
        dispatch(resetPosts());
        await dispatch(fetchMorePosts(1));
      } finally {
        setLoading(false);
      }
    };
    loadInitialPosts();
  }, [dispatch]);

  // for additional posts with infinite scroll (more pages)
  useEffect(() => {
    const fetchPosts = async () => {
      await dispatch(fetchMorePosts(page));
    };
    fetchPosts();
  }, [dispatch, page]);

  useEffect(() => {
    const loadPlaylists = async () => {
      setLoading(true);
      try {
        if (currUser) {
          await dispatch(fetchUserPlaylists(currUser.id));
        }
      } catch (error) {
        console.error('Failed to load playlists:', error);
      } finally {
        setLoading(false);
      }
    };
    loadPlaylists();
  }, [dispatch, currUser]);

  // helper: increments page number and causes dispatch
  const fetchNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // helper: sets playlist object and modal boolean for visibility
  const handleOpenPlaylist = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsModalOpen(true);
  };

  // helper: resets playlist object and modal boolean for non visibility
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPlaylist(null);
  };

  // helper: creates user playlists objects
  const handleCreatePlaylistSubmit = async (playlistData) => {
    try {
      await dispatch(fetchCreateUserPlaylist(playlistData, currUser.id));
      setErrors([]);
    } catch (error) {
      setErrors(error);
    } finally {
      handleCreatePlaylistClose();
      dispatch(fetchUserPlaylists(currUser.id));
    }
  };

  // helper for create playlist modal
  const handleCreatePlaylistOpen = () => setCreatePlaylistModalOpen(true);
  const handleCreatePlaylistClose = () => setCreatePlaylistModalOpen(false);

  // masonry package media query
  const breakpointColumnsObj = {
    default: 4,
    2000: 4,
    1500: 4,
    900: 3,
    600: 2,
    500: 1,
  };

  if (loading || !currUser) return <Loading />;
  if (!posts || Object.values(posts).length === 0) return null;

  return (
    <div id="home-wrap">
      <div id="home-sidebar">
        <div className="sidebar-tile">
          <p>hello {currUser.firstName}.</p>
        </div>
        <div className="sidebar-tile sidebar-collections">
          <div>
            <div className="sidebar-tile">
              <p style={{ fontWeight: "bold" }}>your playlists:</p>
            </div>
          </div>
          {loading ? (
            <Puff color="#808080" height={50} width={50} />
          ) : (
            Object.values(playlists).map((playlist) => (
              <div
                key={playlist.id}
                className="sidebar-onecollection"
                onClick={(e) => handleOpenPlaylist(playlist)}
              >
                <p>{playlist.name}</p>
              </div>
            ))
          )}
          {errors && errors.name && (
            <p style={{ color: "red" }}>playlist name too long</p>
          )}
          <button
            className="sidebar-create-collection"
            onClick={handleCreatePlaylistOpen}
          >
            {" "}
            +{" "}
          </button>
        </div>

        <Modal
          open={createPlaylistModalOpen}
          onClose={handleCreatePlaylistClose}
          aria-labelledby="create-playlist-modal"
          aria-describedby="create-playlist-form"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "220px",
              maxHeight: "100vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <PlaylistForm onSubmit={handleCreatePlaylistSubmit} />
          </Box>
        </Modal>
      </div>

      <TabPanel value={value} index={0}>
        <InfiniteScroll
          dataLength={Object.keys(posts).length}
          next={fetchNextPage}
          hasMore={hasMore}
          loader={<h4></h4>}
          endMessage={
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "4vh",
              }}
            >
              <b>no more current posts.</b>
            </p>
          }
        >
          <div id="home-post-container">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {Object.values(posts).map((post) => (
                <Fade in={true} timeout={500} key={post.id}>
                  <div className="my-masonry-grid_column-item">
                    <Post post={post} sessionUser={currUser} user={user} />
                  </div>
                </Fade>
              ))}
            </Masonry>
          </div>
        </InfiniteScroll>
      </TabPanel>

      {isModalOpen && selectedPlaylist && (
        <>
          <div className="modal-backdrop" onClick={handleCloseModal}></div>
          <PlaylistModal
            playlist={selectedPlaylist}
            currUser={currUser}
            user={currUser}
            handleClose={handleCloseModal}
          />
        </>
      )}
    </div>
  );
}

export default Home;
