import React, { useState } from "react";
import './Post.css';
import UserPostModal from "../UserProfile/UserPost/UserPostModal";
import { Fade } from '@mui/material';
import { Puff } from "react-loader-spinner";


function Post({ post, sessionUser, user }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleViewPostDetail = e => {
    e.preventDefault()
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

    return (
    <div id='allpost-container'>
      {!imageLoaded && (
        <div className="image-placeholder">
          <Puff color="#808080" height={50} width={50}/>
        </div>
      )}
      <img
        className='allpost-image'
        src={post?.photoUrl}
        onClick={handleViewPostDetail}
        onLoad={handleImageLoaded}
        style={{ display: imageLoaded ? 'block' : 'none' }}
        alt={`Post by ${post.User.username}`}
      />
      <div className="allpost-caption">
        <p className="allpost-title">{post.songArtist} - {post.songTitle}</p>
        <p className="allpost-creator">by {post.User.username}</p>
        <div className="allpost-commentcount">
          <i className="fa-sharp fa-regular fa-comment"></i>
          <p>{post.commentCount}</p>
        </div>
      </div>

      {isModalOpen &&
        <Fade in={isModalOpen} timeout={500}>
          <div className="modal-backdrop">
            <div className="modal-backdrop" onClick={handleCloseModal}></div>
            <UserPostModal userPost={post} sessionUser={sessionUser} pageUser={user} handleClose={handleCloseModal}/>
          </div>
        </Fade>
      }
    </div>
  );
}
export default Post
