import React, { useState, useEffect } from 'react';

function BackgroundChanger() {
  const [index, setIndex] = useState(0);
  const images = [
    'https://i.ibb.co/fn1jfmj/tumblr-o0rv4xcco-F1t5l0c7o1-1280-1-fotor-2023101282432.png',
    'https://i.ibb.co/KzwDss8/tumblr-o933rg-IW3-L1ql5zapo1-1280-fotor-2023101282350.jpg',
    'https://i.ibb.co/7n8gJP1/tumblr-mxl0265-Gv51qet0epo1-1280-fotor-20231012105754.jpg',
    'https://i.ibb.co/XkM73qf/tumblr-nk50w2pp-Uy1tvsklwo1-1280-fotor-20231012111628.jpg'
  ];

  useEffect(() => {
    // common preload image technique
    // by creating new js objects and giving it an src, the browser is immediately requesting to fetch the image
    // from the url.
    // its stored in the browsers cache now and will appear much faster on the screen.
    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });

    // my "loop" to change images from images idx 0 -> end + repeat by using modulo formula
    // setInterval
    const intervalId = setInterval(() => {
      setIndex((currentIndex) => (currentIndex + 1) % images.length);
    }, 6000);

    // cleanup function to prevent memory leak
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
    id="background-changer-overlay"
    style={{
      backgroundImage: `url(${images[index]})`,
      transition: 'background-image 2s'
    }}
    >
    </div>
  );
}

export default BackgroundChanger;
